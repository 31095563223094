<template>
    <v-dialog
        v-model="dialog"
        max-width="800"
    >

        <v-card>
            <v-form ref="formInicial" v-model="formValid">

                <v-toolbar color="primary" dark dense>
                    <span class="white--text">Imprime hoja de valoración inicial</span>

                    <v-spacer/>

                    <v-btn text @click="cancela">
                        <v-icon>mdi-close-box</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-actions>
                    <v-spacer/>
                        <v-btn color="primary" @click="print">Imprimir</v-btn>
                        <v-btn color="primary" @click="dialog=false">Cerrar</v-btn>

                </v-card-actions>

                <v-card-text>

                    <v-sheet>
                        <div id="printHojaInicial" :style='{"width":"21.6","height":"28cm", "border": "1px dotted gray"}'>
                            <table :style='{"width":"100%", "border-collapse": "collapse", "line-height": "normal"}'>
                                <thead>
                                    <tr>
                                        <td>
                                            <div>

                                                <div :style='{
                                                    "font-size":"8pt",
                                                    "line-height": "normal",
                                                    "display": "grid",
                                                    "grid-template-columns": "10% 57% 33%",
                                                    "margin":"0px 0px 10px 0px"

                                                }'>


                                                    <div>


                                                        <img
                                                            src="/images/header-03.png"
                                                            height="38px"
                                                        />
                                                    </div>

                                                    <div :style='{
                                                        "text-align":"center",
                                                        "font-size":"9pt",
                                                        "font-weight": "bold"
                                                    }'>
                                                        <div :style='{
                                                            "font-size":"6pt",
                                                            "text-align":"right",
                                                        }'>
                                                            {{datosInter.id}}
                                                        </div>
                                                        (OCSSA001463) HOSPITAL DE LA NIÑEZ OAXAQUEÑA<br/>
                                                        HOJA DE VALORACIÓN INICIAL
                                                    </div>

                                                    <div :style='{
                                                        "text-align":"right"
                                                    }'>

                                                        <img
                                                            src="/images/logo-hno-2020.jpg"
                                                            height="37px"
                                                        />
                                                    </div>
                                                </div>

                                                <div
                                                    :style='{
                                                        "padding":"5px 5px 0px 5px",
                                                    }'
                                                >

                                                    <div
                                                        class="encabezado"
                                                        :style='{
                                                            "display": "flex",
                                                        }'>
                                                        <div >
                                                            <b>
                                                                ({{datosPac.pacienteId}}) {{datosPac.nombreCompleto}} - {{datosPac.edad}} ({{datosPac.fechaNacimiento}} <span v-if="datosPac.sexo=='m'">Hombre</span><span v-if="datosPac.sexo=='f'">Mujer</span>)
                                                            </b>
                                                        </div>

                                                        <div :style='{"flex": "20%", "text-align":"right", "padding": "0px 5px 0px 0px"}'>
                                                            <span>{{moment(datosVal.dateCreated).format('dddd MMMM D, YYYY')}}</span>
                                                        </div>
                                                        <div
                                                            :style='{
                                                                "height": "15px",
                                                                "width": "15px",
                                                                "border-radius": "50%",
                                                                "margin":"5px 4px 0px 0px",
                                                            }'
                                                        ></div>
                                                    </div>
                                                    <div :style='{"padding":"5px", "background-color":"white",}'>
                                                        <datos-generales
                                                            :estaFecha="estaFecha"
                                                            turno="mat"

                                                            :datosInter="datosInter"
                                                            :datosPac="datosPac"
                                                            :datosTurnos="datosTurnos"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </td>
                                    </tr>
                                </thead>



                            </table>

                            <div
                                class="encabezado"
                                :style='{
                                    "display": "flex",
                            }'>
                                <b>Antecedentes patológicos</b>
                            </div>
                            <div class="contenido" :style='{"line-height": "1.5"}'>
                                <div v-for="itm in datosValPato" :key="itm.id">
                                    Antecedente <span v-if="itm.personal"> Personal </span> <span v-if="itm.familiar"> Familiar </span>: {{ itm.antecedente }}. {{ itm.especifica }}
                                </div>
                            </div>

                            <div
                                class="encabezado"
                                :style='{
                                    "display": "flex",
                            }'>
                                <b>Otros</b>
                            </div>
                            <div class="contenido" :style='{"line-height": "1.5"}'>
                                Caries dental: {{ datosVal.cariesDental?"SI":"NO" }}. Piezas completas: {{ datosVal.piezasCompletas?"SI":"NO" }}. Fecha de última revisión: {{ datosVal.fechaUltimaRevision }}<br/>
                                Esquema de vacunación: {{ datosEsquema[datosVal.esquemaVacunacion] }}, Fecha que inicia el esquema: {{ datosVal.fechaIniciaEsquema }}
                            </div>


                            <div
                                class="encabezado"
                                :style='{
                                    "display": "flex",
                            }'>
                                <b>Valoración de persona recien nacida</b>
                            </div>
                            <div class="contenido">
                                {{ datosVal.recienNacido }}
                            </div>

                            <div
                                class="encabezado"
                                :style='{
                                    "display": "flex",
                            }'>
                                <b>Valoración gineco-obstétrica</b>
                            </div>
                            <div class="contenido">
                                {{ datosVal.gineco }}
                            </div>

                            <div
                                class="encabezado"
                                :style='{
                                    "display": "flex",
                            }'>
                                <b>Valoración por aparatos y sistemas</b>
                            </div>

                            <div
                                class="encabezado2"
                                :style='{
                                    "display": "flex",
                            }'>
                                Sistema cardiorespiratorio
                            </div>
                            <div class="contenido">
                                {{ datosVal.cardioRespiratorio }}
                            </div>

                            <div
                            class="encabezado2"
                            :style='{
                                "display": "flex",
                            }'>
                                Sistema digestivo
                            </div>
                            <div class="contenido">
                                {{ datosVal.digestivo }}
                            </div>

                            <div
                                class="encabezado2"
                                :style='{
                                    "display": "flex",
                            }'>
                                Sistema urinario
                            </div>
                            <div class="contenido">
                                {{ datosVal.urinario }}
                            </div>

                            <div
                                class="encabezado2"
                                :style='{
                                    "display": "flex",
                            }'>
                                Sistema genital femenino
                            </div>
                            <div class="contenido">
                                {{ datosVal.femenino }}
                            </div>

                            <div
                                class="encabezado2"
                                :style='{
                                    "display": "flex",
                            }'>
                                Sistema genital masculino
                            </div>
                            <div class="contenido">
                                {{ datosVal.masculino }}
                            </div>

                            <div
                                class="encabezado2"
                                :style='{
                                    "display": "flex",
                            }'>
                                Sistema endocrino
                            </div>
                            <div class="contenido">
                                {{ datosVal.endocrino }}
                            </div>

                            <div
                                class="encabezado2"
                                :style='{
                                    "display": "flex",
                            }'>
                                Sistema nervioso
                            </div>
                            <div class="contenido">
                                {{ datosVal.nervioso }}
                            </div>

                            <div
                                class="encabezado2"
                                :style='{
                                    "display": "flex",
                            }'>
                                Sistema tegumentario
                            </div>
                            <div class="contenido">
                                {{ datosVal.tegumentario }}
                            </div>

                            <div
                                class="encabezado2"
                                :style='{
                                    "display": "flex",
                            }'>
                                Sistema hemático y linfático
                            </div>
                            <div class="contenido">
                                {{ datosVal.hematoLinfatico }}
                            </div>

                            <div
                                class="encabezado"
                                :style='{
                                    "display": "flex",
                            }'>
                                <b>
                                    Aspectos psicológicos, espirituales, emocionales y sociales
                                </b>
                            </div>
                            <div class="contenido">
                                {{ datosVal.psicologicos }}
                            </div>

                            <div
                                class="encabezado2"

                            >
                                <b>Evalua</b>
                            </div>
                            <div
                            :style='{
                                    "padding":"25px 0px 0px 0px",
                                    "text-align":"center"
                                }'
                            >
                                <div class="contenido" :style='{"line-height": "normal"}'>
                                    {{datosValUser.nombreCompleto}}<br/>
                                    {{datosValUser.especialidad}} ({{datosValUser.cedula}})<br/>
                                    {{datosValUser.curp}}<br/>
                                </div>
                            </div>

                        </div>
                    </v-sheet>
                </v-card-text>

            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    components:{
        'datos-generales':()=>import('@/components/hospital/enfe/print/secciones/PrintDatosGenerales'),
    },
    props:{
        dialogModal: Boolean,

        estaFecha:String,
        internamiento:Number,
        turno:String,

        datosInter:Object,
        datosPac:Object,
        datosVal:Object,
        datosValUser:Object,
        datosValPato:Array,
    },

    data:()=>({
        formValid:false,
        loadingBtn:false,
        datosTurnos:{text: 'Matutino', color:'blue'},

        datosEsquema:{
            1: "Competa",
            2: "Incompleta",
            3: "Se desconoce",
        }
    }),

    methods:{
        async guarda(){
            this.dialog=false
        },
        cancela(){
            this.dialog=false
        },

        async print(){
            await this.$htmlToPaper('printHojaInicial',{
                styles:[
                    '/css/hojaenf.css'
                ],
            })
        },
    },

    computed:{
        dialog:{
            get(){return this.dialogModal},
            set(val){this.$emit('update:dialogModal',val)}
        }
    },

    watch:{
        dialog(v){
            if (!v){
                this.$refs.formInicial.reset()
            }
        }
    },
}
</script>

<style>

@media screen {
    @page {
        size: letter portrait;
    }
    .onlyPrint {
        display: none;
    }
    .divZona{
        background-color:white;
        margin: 3px 0px 3px 0px;
        border-radius: 10px;
    }

    .encabezado{
        padding: 0px 0px 2px 15px;
        border-radius: 10px 10px 0px 0px;
        background-color:#DCDCDC;
        font-weight: 400;
        font-size:10pt;

    }
    .contenido{
        padding: 0px 30px;
        font-weight: 400;
        font-size:8pt;

    }
    .encabezado2{
        padding: 0px 0px 2px 15px;
        background-color:#DCDCDC;
        font-weight: 400;
        font-size:10pt;

    }
    .wrapper {
        display: grid;
        grid-column-gap: 3px;
        grid-template-columns: 40% 60%;
        column-gap: 3px;
    }

    .col1{
        padding: 0px 2px 0px 4px;
    }
    .col2{
        padding: 0px 4px 0px 2px;
    }
}
</style>