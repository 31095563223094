var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-form',{ref:"formInicial",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","dense":""}},[_c('span',{staticClass:"white--text"},[_vm._v("Imprime hoja de valoración inicial")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.cancela}},[_c('v-icon',[_vm._v("mdi-close-box")])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.print}},[_vm._v("Imprimir")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog=false}}},[_vm._v("Cerrar")])],1),_c('v-card-text',[_c('v-sheet',[_c('div',{style:({"width":"21.6","height":"28cm", "border": "1px dotted gray"}),attrs:{"id":"printHojaInicial"}},[_c('table',{style:({"width":"100%", "border-collapse": "collapse", "line-height": "normal"})},[_c('thead',[_c('tr',[_c('td',[_c('div',[_c('div',{style:({
                                                "font-size":"8pt",
                                                "line-height": "normal",
                                                "display": "grid",
                                                "grid-template-columns": "10% 57% 33%",
                                                "margin":"0px 0px 10px 0px"

                                            })},[_c('div',[_c('img',{attrs:{"src":"/images/header-03.png","height":"38px"}})]),_c('div',{style:({
                                                    "text-align":"center",
                                                    "font-size":"9pt",
                                                    "font-weight": "bold"
                                                })},[_c('div',{style:({
                                                        "font-size":"6pt",
                                                        "text-align":"right",
                                                    })},[_vm._v(" "+_vm._s(_vm.datosInter.id)+" ")]),_vm._v(" (OCSSA001463) HOSPITAL DE LA NIÑEZ OAXAQUEÑA"),_c('br'),_vm._v(" HOJA DE VALORACIÓN INICIAL ")]),_c('div',{style:({
                                                    "text-align":"right"
                                                })},[_c('img',{attrs:{"src":"/images/logo-hno-2020.jpg","height":"37px"}})])]),_c('div',{style:({
                                                    "padding":"5px 5px 0px 5px",
                                                })},[_c('div',{staticClass:"encabezado",style:({
                                                        "display": "flex",
                                                    })},[_c('div',[_c('b',[_vm._v(" ("+_vm._s(_vm.datosPac.pacienteId)+") "+_vm._s(_vm.datosPac.nombreCompleto)+" - "+_vm._s(_vm.datosPac.edad)+" ("+_vm._s(_vm.datosPac.fechaNacimiento)+" "),(_vm.datosPac.sexo=='m')?_c('span',[_vm._v("Hombre")]):_vm._e(),(_vm.datosPac.sexo=='f')?_c('span',[_vm._v("Mujer")]):_vm._e(),_vm._v(") ")])]),_c('div',{style:({"flex": "20%", "text-align":"right", "padding": "0px 5px 0px 0px"})},[_c('span',[_vm._v(_vm._s(_vm.moment(_vm.datosVal.dateCreated).format('dddd MMMM D, YYYY')))])]),_c('div',{style:({
                                                            "height": "15px",
                                                            "width": "15px",
                                                            "border-radius": "50%",
                                                            "margin":"5px 4px 0px 0px",
                                                        })})]),_c('div',{style:({"padding":"5px", "background-color":"white",})},[_c('datos-generales',{attrs:{"estaFecha":_vm.estaFecha,"turno":"mat","datosInter":_vm.datosInter,"datosPac":_vm.datosPac,"datosTurnos":_vm.datosTurnos}})],1)])])])])])]),_c('div',{staticClass:"encabezado",style:({
                                "display": "flex",
                        })},[_c('b',[_vm._v("Antecedentes patológicos")])]),_c('div',{staticClass:"contenido",style:({"line-height": "1.5"})},_vm._l((_vm.datosValPato),function(itm){return _c('div',{key:itm.id},[_vm._v(" Antecedente "),(itm.personal)?_c('span',[_vm._v(" Personal ")]):_vm._e(),_vm._v(" "),(itm.familiar)?_c('span',[_vm._v(" Familiar ")]):_vm._e(),_vm._v(": "+_vm._s(itm.antecedente)+". "+_vm._s(itm.especifica)+" ")])}),0),_c('div',{staticClass:"encabezado",style:({
                                "display": "flex",
                        })},[_c('b',[_vm._v("Otros")])]),_c('div',{staticClass:"contenido",style:({"line-height": "1.5"})},[_vm._v(" Caries dental: "+_vm._s(_vm.datosVal.cariesDental?"SI":"NO")+". Piezas completas: "+_vm._s(_vm.datosVal.piezasCompletas?"SI":"NO")+". Fecha de última revisión: "+_vm._s(_vm.datosVal.fechaUltimaRevision)),_c('br'),_vm._v(" Esquema de vacunación: "+_vm._s(_vm.datosEsquema[_vm.datosVal.esquemaVacunacion])+", Fecha que inicia el esquema: "+_vm._s(_vm.datosVal.fechaIniciaEsquema)+" ")]),_c('div',{staticClass:"encabezado",style:({
                                "display": "flex",
                        })},[_c('b',[_vm._v("Valoración de persona recien nacida")])]),_c('div',{staticClass:"contenido"},[_vm._v(" "+_vm._s(_vm.datosVal.recienNacido)+" ")]),_c('div',{staticClass:"encabezado",style:({
                                "display": "flex",
                        })},[_c('b',[_vm._v("Valoración gineco-obstétrica")])]),_c('div',{staticClass:"contenido"},[_vm._v(" "+_vm._s(_vm.datosVal.gineco)+" ")]),_c('div',{staticClass:"encabezado",style:({
                                "display": "flex",
                        })},[_c('b',[_vm._v("Valoración por aparatos y sistemas")])]),_c('div',{staticClass:"encabezado2",style:({
                                "display": "flex",
                        })},[_vm._v(" Sistema cardiorespiratorio ")]),_c('div',{staticClass:"contenido"},[_vm._v(" "+_vm._s(_vm.datosVal.cardioRespiratorio)+" ")]),_c('div',{staticClass:"encabezado2",style:({
                            "display": "flex",
                        })},[_vm._v(" Sistema digestivo ")]),_c('div',{staticClass:"contenido"},[_vm._v(" "+_vm._s(_vm.datosVal.digestivo)+" ")]),_c('div',{staticClass:"encabezado2",style:({
                                "display": "flex",
                        })},[_vm._v(" Sistema urinario ")]),_c('div',{staticClass:"contenido"},[_vm._v(" "+_vm._s(_vm.datosVal.urinario)+" ")]),_c('div',{staticClass:"encabezado2",style:({
                                "display": "flex",
                        })},[_vm._v(" Sistema genital femenino ")]),_c('div',{staticClass:"contenido"},[_vm._v(" "+_vm._s(_vm.datosVal.femenino)+" ")]),_c('div',{staticClass:"encabezado2",style:({
                                "display": "flex",
                        })},[_vm._v(" Sistema genital masculino ")]),_c('div',{staticClass:"contenido"},[_vm._v(" "+_vm._s(_vm.datosVal.masculino)+" ")]),_c('div',{staticClass:"encabezado2",style:({
                                "display": "flex",
                        })},[_vm._v(" Sistema endocrino ")]),_c('div',{staticClass:"contenido"},[_vm._v(" "+_vm._s(_vm.datosVal.endocrino)+" ")]),_c('div',{staticClass:"encabezado2",style:({
                                "display": "flex",
                        })},[_vm._v(" Sistema nervioso ")]),_c('div',{staticClass:"contenido"},[_vm._v(" "+_vm._s(_vm.datosVal.nervioso)+" ")]),_c('div',{staticClass:"encabezado2",style:({
                                "display": "flex",
                        })},[_vm._v(" Sistema tegumentario ")]),_c('div',{staticClass:"contenido"},[_vm._v(" "+_vm._s(_vm.datosVal.tegumentario)+" ")]),_c('div',{staticClass:"encabezado2",style:({
                                "display": "flex",
                        })},[_vm._v(" Sistema hemático y linfático ")]),_c('div',{staticClass:"contenido"},[_vm._v(" "+_vm._s(_vm.datosVal.hematoLinfatico)+" ")]),_c('div',{staticClass:"encabezado",style:({
                                "display": "flex",
                        })},[_c('b',[_vm._v(" Aspectos psicológicos, espirituales, emocionales y sociales ")])]),_c('div',{staticClass:"contenido"},[_vm._v(" "+_vm._s(_vm.datosVal.psicologicos)+" ")]),_c('div',{staticClass:"encabezado2"},[_c('b',[_vm._v("Evalua")])]),_c('div',{style:({
                                "padding":"25px 0px 0px 0px",
                                "text-align":"center"
                            })},[_c('div',{staticClass:"contenido",style:({"line-height": "normal"})},[_vm._v(" "+_vm._s(_vm.datosValUser.nombreCompleto)),_c('br'),_vm._v(" "+_vm._s(_vm.datosValUser.especialidad)+" ("+_vm._s(_vm.datosValUser.cedula)+")"),_c('br'),_vm._v(" "+_vm._s(_vm.datosValUser.curp)),_c('br')])])])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }